var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "客户报表",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "筛选"
    },
    on: {
      "click-right": _vm.handleClickRight
    }
  }), _c('div', {
    staticClass: "filter-container"
  }, [_c('van-search', {
    attrs: {
      "placeholder": "客户名称",
      "clearable": ""
    },
    on: {
      "search": _vm.reload
    },
    model: {
      value: _vm.listQuery.name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "name", $$v);
      },
      expression: "listQuery.name"
    }
  }), _vm.isSuperman ? _c('van-cell', {
    attrs: {
      "title": "门店",
      "value": _vm.storeName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showActions = true;
      }
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "月份",
      "value": _vm.listQuery.month,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showDatePicker = true;
      }
    }
  })], 1), _c('van-list', {
    ref: "list",
    class: 'list ' + (_vm.isSuperman ? 'is-superman' : ''),
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (client) {
    return _c('van-cell', {
      key: client.id,
      attrs: {
        "value-class": "cell-value",
        "is-link": "",
        "center": "",
        "value": '￥' + (client.amount ? client.amount : 0),
        "title-class": "cell-title"
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(client);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('div', {
            staticClass: "cell-title-custom"
          }, [_c('span', [_vm._v(_vm._s(client.name))]), _c('div', [client.plan_amount ? _c('van-image', {
            staticClass: "plan-icon",
            attrs: {
              "fit": "contain",
              "src": _vm._f("planIconFilter")(client.plan_amount)
            }
          }) : _vm._e()], 1)])];
        },
        proxy: true
      }, {
        key: "label",
        fn: function fn() {
          return [client.plan_amount ? _c('div', [_vm._v("已签定：" + _vm._s(client.plan_amount) + "元")]) : _vm._e(), client.plan_amount ? _c('div', [_vm._v("已完成：" + _vm._s(client.plan_finished_amount) + "元")]) : _vm._e(), client.last_order_datetime ? _c('div', [_vm._v("最后下单时间：" + _vm._s(client.last_order_datetime))]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    });
  })], 2), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelect
    },
    model: {
      value: _vm.showActions,
      callback: function callback($$v) {
        _vm.showActions = $$v;
      },
      expression: "showActions"
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDatePicker,
      callback: function callback($$v) {
        _vm.showDatePicker = $$v;
      },
      expression: "showDatePicker"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "year-month",
      "title": "选择月份"
    },
    on: {
      "confirm": _vm.handleDatePickerConfirm,
      "cancel": function cancel($event) {
        _vm.showDatePicker = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.filterActions
    },
    on: {
      "select": _vm.handleSelectFilter
    },
    model: {
      value: _vm.showFilterActions,
      callback: function callback($$v) {
        _vm.showFilterActions = $$v;
      },
      expression: "showFilterActions"
    }
  }), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.lineActions
    },
    on: {
      "select": _vm.handleSelectLine
    },
    model: {
      value: _vm.showLineActions,
      callback: function callback($$v) {
        _vm.showLineActions = $$v;
      },
      expression: "showLineActions"
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showAreaPicker,
      callback: function callback($$v) {
        _vm.showAreaPicker = $$v;
      },
      expression: "showAreaPicker"
    }
  }, [_c('van-area', {
    attrs: {
      "title": "选择区域",
      "area-list": _vm.areaList
    },
    on: {
      "confirm": _vm.handleSelectArea,
      "cancel": function cancel($event) {
        _vm.showAreaPicker = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }