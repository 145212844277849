<template>
  <div>
    <my-nav-bar
      title="客户报表"
      left-text="返回"
      left-arrow
      right-text="筛选"
      @click-right="handleClickRight"
    />
    <div class="filter-container">
      <van-search v-model="listQuery.name" placeholder="客户名称" clearable @search="reload" />
      <van-cell v-if="isSuperman" title="门店" :value="storeName" is-link center @click="showActions = true" />
      <van-cell title="月份" :value="listQuery.month" is-link center @click="showDatePicker = true" />
    </div>
    <van-list
      ref="list"
      v-model="loading"
      :class="'list ' + (isSuperman ? 'is-superman' : '')"
      :finished="finished"
      @load="getList"
    >
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell
        v-for="client in list"
        :key="client.id"
        value-class="cell-value"
        is-link
        center
        :value="'￥' + (client.amount ? client.amount : 0)"
        title-class="cell-title"
        @click="toDetailView(client)"
      >
        <template #title>
          <div class="cell-title-custom">
            <span>{{ client.name }}</span>
            <div>
              <van-image v-if="client.plan_amount" fit="contain" :src="client.plan_amount | planIconFilter" class="plan-icon" />
            </div>
          </div>
        </template>
        <template #label>
          <div v-if="client.plan_amount">已签定：{{ client.plan_amount }}元</div>
          <div v-if="client.plan_amount">已完成：{{ client.plan_finished_amount }}元</div>
          <div v-if="client.last_order_datetime">最后下单时间：{{ client.last_order_datetime }}</div>
        </template>
      </van-cell>
    </van-list>
    <van-action-sheet v-model="showActions" :actions="actions" @select="handleSelect" />
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择月份"
        @confirm="handleDatePickerConfirm"
        @cancel="showDatePicker = false"
      />
    </van-popup>
    <van-action-sheet v-model="showFilterActions" :actions="filterActions" @select="handleSelectFilter" />
    <van-action-sheet v-model="showLineActions" :actions="lineActions" @select="handleSelectLine" />
    <van-popup v-model="showAreaPicker" position="bottom">
      <van-area title="选择区域" :area-list="areaList" @confirm="handleSelectArea" @cancel="showAreaPicker = false" />
    </van-popup>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getClients, getLines } from '@/api/client-report'
import { currentMonth, toDateStringMonth, strpad } from '@/utils'
import * as areaData from 'china-area-data'
const planAmountMaps = {
  '8000.00': require('@/assets/images/baiyin-3.png'),
  '10000.00': require('@/assets/images/huangjin-3.png'),
  '15000.00': require('@/assets/images/zuanshi-3.png'),
  '20000.00': require('@/assets/images/heijin-3.png')
}
import { getStores } from '@/api/home'
export default {
  name: 'ClientReport',
  components: { myNavBar },
  filters: {
    planIconFilter(amount) {
      return planAmountMaps[amount]
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      showEmpty: false,
      list: [],
      listQuery: {
        page: 0,
        limit: 20,
        name: '',
        store_id: '',
        month: currentMonth(),
        line_id: '',
        district_code: ''
      },
      currentDate: new Date(),
      showActions: false,
      actions: [],
      storeName: '',
      showDatePicker: false,
      showFilterActions: false,
      filterActions: [
        { name: '线路', value: 1 },
        { name: '区域', value: 2 }
      ],
      showLineActions: false,
      lineActions: [],
      isSuperman: this.$store.state.userInfo.type === 1,
      showAreaPicker: false,
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {}
      },
      specialCodeMaps: {}
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    this.getClientLineList()
    const specialCodes = ['441900', '442000', '460400']
    Object.keys(areaData).forEach(code => {
      if (code === '86') {
        this.areaList.province_list = areaData[code]
      } else if (code.search('0000') !== -1) {
        Object.keys(areaData[code]).forEach(cityCode => {
          this.areaList.city_list[cityCode] = areaData[code][cityCode]
        })
      } else {
        let index = 1
        const prefix = code.substring(0, 4)
        Object.keys(areaData[code]).forEach(districtCode => {
          if (specialCodes.includes(code)) {
            const specialCode = prefix + strpad(index, 2)
            this.specialCodeMaps[specialCode] = districtCode
            index++
            this.areaList.county_list[specialCode] = areaData[code][districtCode]
          } else {
            this.areaList.county_list[districtCode] = areaData[code][districtCode]
          }
        })
      }
    })

    getStores().then(res => {
      res.data.map(store => {
        this.actions.push({
          name: store.name,
          value: store.id
        })
      })
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/client-report-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getClients(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    getClientLineList() {
      this.lineActions = []
      const params = {
        store_id: this.listQuery.store_id
      }
      getLines(params).then(res => {
        res.data.forEach(line => {
          this.lineActions.push({
            name: `${line.name}(${line.store.name})`,
            value: line.id
          })
        })
      })
    },
    reload() {
      this.listQuery.page = 0
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.$refs.list.check()
    },
    handleSelect(action) {
      this.storeName = action.name
      this.listQuery.store_id = action.value
      this.showActions = false
      this.reload()
      this.getClientLineList()
    },
    handleDatePickerConfirm(date) {
      this.listQuery.month = toDateStringMonth(date)
      this.reload()
      this.showDatePicker = false
    },
    toDetailView(client) {
      this.$router.push({
        path: '/client-report-detail',
        query: {
          client_id: client.id,
          month: this.listQuery.month
        }
      })
    },
    handleClickRight() {
      this.showFilterActions = true
    },
    handleSelectFilter(action) {
      this.showFilterActions = false
      if (action.value === 1) {
        this.showLineActions = true
      } else {
        this.showAreaPicker = true
      }
    },
    handleSelectLine(action) {
      this.listQuery.line_id = action.value
      this.showLineActions = false
      this.reload()
    },
    handleSelectArea(areas) {
      if (areas[2]) {
        let code = this.specialCodeMaps[areas[2].code]
        if (!code) {
          code = areas[2].code
        }
        this.listQuery.district_code = code
        this.reload()
      }

      this.showAreaPicker = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .filter-container {
    position: fixed;
    left: 0;
    top: 46px;
    right: 0;
    z-index: 9;
  }
  .list {
    margin-top: 154px;
    margin-bottom: env(safe-area-inset-bottom);
    margin-bottom: constant(safe-area-inset-bottom);
  }
  .is-superman {
    margin-top: 198px!important;
  }
  .cell-title {
    flex: 3;
  }
  .cell-value {
    color: $amountRedColor;
  }
  .cell-title-custom {
    display: flex;
    align-items: center;
  }
  .plan-icon {
    width: 55px;
    margin-left: 5px;
    display: block;
  }
</style>
